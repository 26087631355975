//---------//
// Imports //
//---------//

import path from 'path'

//
//------//
// Init //
//------//

// const domain = 'localhost:7877',
const domain = 'test.passthequill.com',
  protocol = domain.startsWith('localhost') ? 'http' : 'https',
  liveUpdateWebsocketPort = 7878,
  serverPort = 8080,
  logDir = domain.startsWith('localhost') ? 'localhost' : domain

//
//------//
// Main //
//------//

//
// TODO: look into authentication alternatives
// I didn't look into the authentication mechanisms much, but they did note
//   basic auth to be lesser performant.
//
const authorEmail = `phil@test.passthequill.com`,
  couchdbAuth = {
    username: 'phil',
    password: 'nEyn7MTRPKbNr479fGIrIQqiaE5gKEhS',
  },
  baseUrl = {
    couchdb: 'http://couchdb:5984',
    external: `${protocol}://${domain}`,
    local: `http://localhost:${serverPort}`,
  },
  emailTemplateDir = process.env.IS_BUILDING
    ? path.resolve(__dirname, '../lib/server/email/templates')
    : '/usr/lib/passthequill.com/email-templates',
  liveUpdateWebsocket = {
    port: liveUpdateWebsocketPort,
    url: domain.startsWith('localhost')
      ? `ws://localhost:${liveUpdateWebsocketPort}`
      : `wss://${domain}/ws/`,
  },
  logDirectory = process.env.IS_BUILDING ? './build-log' : `/var/log/${logDir}`,
  mailHost = 'mail.philipolsonm.com',
  salt = {
    email: '$2a$11$eMKM3cKwBb/cjQDpc18PW.',
    hashid: 'Mu0MzXl4bppsYZ1USy8L_ZE5IwZ0M9xI',
  },
  smtpAuth = {
    user: `info@test.passthequill.com`,
    pass: 'CWPTZWpM7D1bJiqwrlBAQQ4PHAS_JP9Q',
  }

//
//---------//
// Exports //
//---------//

export {
  authorEmail,
  baseUrl,
  couchdbAuth,
  domain,
  emailTemplateDir,
  liveUpdateWebsocket,
  logDirectory,
  mailHost,
  salt,
  serverPort,
  smtpAuth,
}
